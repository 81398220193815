import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import Index from "@/views/index";
import centerRouter from "./center";
import course from "./course";
import refer from "./refer";
import leetcode from "./leetcode";
import answerCenter from "./answerCenter";
import mock from "./mock";
import live from "./live";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "index",
    meta: {
      title: "北美CSON-专注一线大厂算法面试"
    },
    component: Index,
  },
  // {
  //     path: '/mode',
  //     name: 'mode',
  //     component: () =>
  //         import ('@/views/ban/mode.vue')
  // },
  {
    path: "/activity",
    name: "activity",
    component: () => import("@/views/activity/activity.vue"),
    meta: {
      title: "邀好友 赠课程 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/activity-m",
    name: "activity-m",
    component: () => import("@/views/activity/activity-m.vue"),
  },
  {
    path: "/activity-ask",
    name: "activity-ask",
    component: () => import("@/views/activity/activity-ask.vue"),
  },
  {
    path: "/wechatRegister",
    name: "wechatRegister",
    component: () => import("@/views/user/wechatRegister.vue"),
  },
  {
    path: "/bindEmail",
    name: "bindEmail",
    component: () => import("@/views/user/bindEmail.vue"),
  },
  {
    path: "/wechatLogin",
    name: "wechatLogin",
    component: () => import("@/views/user/wechatLogin.vue"),
  },
  {
    path: "/registerform",
    name: "registerform",
    meta: {
      title: "欢迎加入CSON",
    },
    component: () => import("@/views/user/registerform.vue"),
  },
  {
    path: "/wechatBindingEmail",
    name: "wechatBindingEmail",
    meta: {
      title: "欢迎加入CSON",
    },
    component: () => import("@/views/user/WechatBindingEmail.vue"),
  },
  // {
  //     path: '/login',
  //     name: 'login',
  //     meta: {
  //         title: "用户登录 - CSON"
  //     },
  //     component: () =>
  //         import('@/views/user/login.vue')
  // },
  {
    path: "/findpassword",
    name: "findpassword",
    meta: {
      title: "找回密码 - CSON",
    },
    component: () => import("@/views/user/findpassword.vue"),
  },
  {
    path: "/setpassword",
    name: "setpassword",
    // meta: { requireAuth: true },
    component: () => import("@/views/user/setpassword.vue"),
  },
  {
    path: "/audition",
    name: "audition",
    component: () => import("@/views/audition/audition.vue"),
  },
  {
    path: "/auditionWatch",
    name: "auditionWatch",
    component: () => import("@/views/audition/auditionWatch.vue"),
  },

  {
    path: "/story",
    name: "story",
    component: () => import("@/views/story/story.vue"),
    meta: {
      title: "创始人故事 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/groupBuy",
    name: "groupBuy",
    component: () => import("@/views/pintuan/pintuan.vue"),
  },
  {
    path: "/groupBuyDetail",
    name: "groupBuyDetail",
    component: () => import("@/views/pintuan/pintuan-2.vue"),
  },
  {
    path: "/harken",
    name: "harken",
    component: () => import("@/views/harken/harken.vue"),
    meta: {
      title: "倾听 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/harkenDetail",
    name: "harkenDetail",
    component: () => import("@/views/harken/harkenDetail.vue"),
  },
  {
    path: "/course",
    name: "course",
    meta: {
      title: "求职面试全家桶 -专注一线大厂算法面试 -CSON",
    },
    component: () => import("@/views/chooseguide/chooseguide.vue"),
  },
  {
    path: "/leetcode",
    name: "leetcode",
    meta: {
      title: "LC分类顺序表 - 专注一线大厂算法面试 -CSON",
    },
    component: () => import("@/views/seqlist/seqlist.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    meta: { requireAuth: true },
    component: () => import("@/views/cart/cart.vue"),
  },
  {
    path: "/confirm",
    name: "confirm",
    meta: { requireAuth: true },
    component: () => import("@/views/cart/confirm.vue"),
  },
  {
    path: "/orderpay",
    name: "orderpay",
    meta: { requireAuth: true },
    component: () => import("@/views/cart/orderpay.vue"),
  },
  {
    path: "/pay/success",
    name: "success",
    meta: {
      title: "支付成功 -CSON",
    },
    component: () => import("@/views/cart/payresult.vue"),
  },
  {
    path: "/pay/failure",
    name: "payFailure",
    meta: {
      title: "支付失败 -CSON",
    },
    component: () => import("@/views/cart/payFailure.vue"),
  },
  {
    path: "/pay/confirm",
    name: "confirm",
    meta: { requireAuth: true },
    component: () => import("@/views/cart/payment.vue"),
  },
  {
    path: "/payform",
    name: "payform",
    meta: { requireAuth: true },
    component: () => import("@/views/cart/payForm.vue"),
  },
  {
    path: "/notice",
    name: "notice",
    component: () => import("@/views/notice/list.vue"),
  },
  {
    path: "/message",
    name: "message",
    meta: {
      title: "通知中心 - 专注北美算法面试 - CSON",
      requireAuth: true,
    },
    component: () => import("@/views/message/list.vue"),
  },
  {
    path: "/question",
    name: "question",
    meta: {
      title: "帮助中心 - 专注北美算法面试 - CSON",
    },
    component: () => import("@/views/question/question.vue"),
  },
  {
    path: "/course/video",
    meta: { requireAuth: true },
    name: "video",
    component: () => import("@/views/broadcast/broadcast.vue"),
  },
  {
    path: "/lessoncenter",
    meta: { requireAuth: true },
    name: "lessoncenter",
    component: () => import("@/views/center/mylesson/lessoncenter.vue"),
  },
  {
    path: "/userVerifycation",
    name: "userVerifycation",
    component: () => import("@/views/user/userVerifycation.vue"),
  },
  {
    path: "/needEmailRegister",
    name: "needEmailRegister",
    component: () => import("@/views/user/needEmailRegister.vue"),
  },
  // start
  {
    path: "/punchClock",
    name: "punchClock",
    // meta: {
    //     title: ""
    // },
    component: () => import("@/views/punchClock/punchClock.vue"),
    meta: {
      title: "刷题打卡 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/punchClockInit",
    name: "punchClockInit",
    meta: { requireAuth: true, title: "我的打卡 - 专注北美算法面试 - CSON" },
    component: () => import("@/views/punchClock/myClock/punchClockInit.vue"),
  },
  {
    path: "/watchVideo",
    name: "watchVideo",
    meta: { requireAuth: true },
    component: () => import("@/views/broadcast/broadcastLeetCode.vue"),
  },
  {
    path: "/myGroup",
    name: "myGroup",
    component: () => import("@/views/punchClock/myGroup.vue"),
  },
  {
    path: "/activityDetail",
    name: "activityDetail",
    component: () => import("@/views/punchClock/activityDetail.vue"),
  },
  {
    path: "/VIPadvertise",
    name: "solve",
    component: () => import("@/views/activity/VIPadvertise.vue"),
  },
  // {
  //     path: '/officialGroup',
  //     name: 'officialGroup',
  //     component: () =>
  //         import ('@/views/punchClock/officialGroup.vue')
  // },

  ...course,
  ...refer,
  ...centerRouter,
  {
    path: "*",
    component: () => import("@/views/pageError/pageNotFound.vue"),
  },
  {
    path: "/pageNotFound",
    component: () => import("@/views/pageError/pageNotFound.vue"),
  },
  {
    path: "/errorRequest",
    component: () => import("@/views/pageError/500.vue"),
  },
  {
    path: "/forbid",
    component: () => import("@/views/pageError/400.vue"),
  },
  {
    path: "/serverError",
    component: () => import("@/views/pageError/403.vue"),
  },
  {
    path: "/serviceUnavailable",
    component: () => import("@/views/pageError/503.vue"),
  },
  {
    path: "/interviewExperience",
    name: "interviewExperience",
    component: () => import("@/views/interview/interviewHome.vue"),
    meta: {
      keepAlive: true,
      title: "面经 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/interviewExpression",
    name: "interviewExpression",
    component: () =>
      import("@/views/interviewExpression/interviewExpression.vue"),
    meta: {
      keepAlive: true,
      title: "经感 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/interviewExpDetail",
    name: "interviewExpDetail",
    component: () =>
      import("@/views/interviewExpression/interviewExpDetail.vue"),
    meta: {
      title: "经感 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/editExpression",
    name: "editExpression",
    component: () => import("@/views/interviewExpression/editExpression.vue"),
    meta: {
      title: "撰写经感 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/interviewDetail",
    name: "interviewDetail",
    component: () => import("@/views/interview/interviewDetail.vue"),
  },
  {
    path: "/interviewPlan",
    name: "interviewPlan",
    component: () => import("@/views/interview/interviewPlan.vue"),
    meta: {
      title: "面经协作计划 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/interviewEdit",
    name: "interviewEdit",
    component: () => import("@/views/interview/interviewEdit.vue"),
    meta: {
      title: "撰写面经 - 专注北美算法面试 - CSON",
      requireAuth: true,
    },
  },
  {
    path: "/company",
    name: "company",
    component: () => import("@/views/company/company.vue"),
    meta: {
      title: "公司 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/companyDetail",
    name: "companyDetail",
    component: () => import("@/views/company/companyDetail.vue"),
  },
  {
    path: "/questionDetail",
    name: "questionDetail",
    component: () => import("@/views/interview/questDetail.vue"),
  },
  ...live,
  {
    path: "/newCourse",
    name: "newCourse",
    component: () => import("@/views/ban/components/newCourse.vue"),
  },
  {
    path: "/refer",
    name: "refer",
    component: () => import("@/views/refer/referPlan.vue"),
    meta: {
      title: "北美内推 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/companyList",
    name: "recruitmentResourceSharing",
    component: () => import("@/views/recruitmentResourceSharing/index.vue"),
    meta: {
      title: "北美公司 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/interpolation",
    name: "interpolation",
    component: () => import("@/views/interpolation/index.vue"),
    meta: {
      title: "北美内推 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/provideResource",
    name: "provideResource",
    component: () =>
      import("@/views/recruitmentResourceSharing/provideResource.vue"),
    meta: {
      title: "提供信息 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/trends",
    name: "trends",
    component: () => import("@/views/trends/index.vue"),
    meta: {
      title: "动态 - 专注北美算法面试 - CSON",
      requireAuth: true,
    },
  },
  {
    path: "/userPage",
    name: "userPage",
    meta: {
      title: "用户个人主页 - CSON",
      requireAuth: true,
    },
    component: () => import("@/views/trends/userPage.vue"),
  },

  {
    path: "/activityFreeCourse",
    name: "activityFreeCourse",
    component: () => import("@/views/activity/activity0929.vue"),
    meta: {
      title: "免费课程 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: () => import("@/views/subscribe/index.vue"),
    meta: {
      title: "会员订阅 - 专注北美算法面试 - CSON",
    },
  },
  {
    path: "/subscribe/choosePlan",
    name: "choosePlan",
    component: () => import("@/views/subscribe/choosePlan.vue"),
    meta: {
      title: "选择会员订阅方案 - 专注北美算法面试 - CSON",
      requireAuth: true,
    },
  },
  {
    path: "/research",
    name: "research",
    component: () => import("@/views/research/index.vue"),
    meta: {
      title: "学员基本情况调查 - 专注北美算法面试 - CSON",
      requireAuth: true,
    },
  },
  {
    path: "/referRegister",
    name: "referRegister",
    component: () => import("@/views/refer/referRegister.vue"),
    meta: {
      title: "内推人注册 - 专注北美算法面试 - CSON",
      requireAuth: true,
    },
  },
  {
    path: "/studyAbroadApply", //北美CS留学申请
    component: () => import("@/views/ban/0-liuxue.vue"),
    name: "apply",
    meta: {
      title: "北美CS留学申请  - 专注一线大厂算法面试 -CSON",
    },
    children: [],
  },
  {
    path: "/login",
    name: "login_1",
    meta: {
      title: "用户登录 - CSON",
    },
    component: () => import("@/views/1_user/login/login.vue"),
  },

  // {
  //   path: "/haioffer",
  //   name: "haioffer",
  //   meta: {
  //     title: "北美职位",
  //     keepAlive: true,
  //   },
  //   component: () => import("@/views/2_haioffer/offer.vue"),
  // },
  {
    path: "/offer/companyDetail",
    name: "offerCompanyDetail",
    meta: {
      title: "公司详情",
    },
    component: () => import("@/views/2_haioffer/components/companyDetail.vue"),
  },
  {
    path: "/account",
    name: "account",
    meta: {
      title: "账号",
    },
    component: () => import("@/views/1_user/account/account.vue"),
  },
  {
    path: "/bindPhone",
    name: "bindPhone",
    meta: {
      title: "绑定手机",
    },
    component: () => import("@/views/1_user/account/BindPhone.vue"),
  },
  ...leetcode,
  ...answerCenter,
  ...mock,
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem("CSON_PAGE_TOKEN");
  if (to.meta.requireAuth) {
    console.log(to.meta);
    if (token) {
      next();
    } else {
      console.log("?");
      next({
        path: "/login", //指向为你的登录界面
      });
    }
  } else {
    next();
  }

  // if (to.fullPath === "/login") {
  //     console.log(token)
  //     if (token) {
  //         next({
  //             path: from.fullPath
  //         });
  //     } else {
  //         next();
  //     }
  // }
  // if(!this.userInfo.emailStatus)
  // if (to.fullPath === "/") {
  //     next();
  //     // if (token) {
  //     //     next({
  //     //         path: "/course"
  //     //     });
  //     // } else {
  //     //     next();
  //     // }
  // }
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});
// const token = window.localStorage.getItem('CSON_PAGE_TOKEN');
export default router;
